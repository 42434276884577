export const JOBS = [
    {
        company:     "Faire",
        position:    "Platform Engineer Intern",
        startDate:   "May 2023",
        endDate:     "Aug. 2023",
        description: "Developed a database migration tool and a query execution tool and enhanced an internal deployment automation tool."
    },
    {
        company:     "Faire",
        position:    "Backend Engineer Intern",
        startDate:   "Sept. 2022",
        endDate:     "Dec. 2022",
        description: "Developed currency infrastructure, allowing brands to set prices in multiple currencies."
    },
    {
        company:     "Faire",
        position:    "Platform Engineer Intern",
        startDate:   "Jan. 2022",
        endDate:     "April 2022",
        description: "Expanded a tool to automate database migrations and provide users with live updates. Used Jenkins AWS Secrets Manager to prevent unnecessary restarts of Jenkins instances."
    },
    {
        company:     "Ecobee",
        position:    "Backend Engineer Intern",
        startDate:   "May 2021",
        endDate:     "Aug. 2021",
        description: "Led the breakup of a monolithic server into microservices and migrated infrastructure to GCP."
    },
    {
        company:     "ODS",
        position:    "Full-Stack Engineer Intern",
        startDate:   "Sept. 2020",
        endDate:     "Dec. 2020",
        description: "Launched Ontario’s live COVID-19 data visualizations and microsites. Built the frontend of ServiceOntario’s new appointment-booking system, reducing walk-ins and wait times."
    },
];
