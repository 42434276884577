import {React, useState, useEffect} from "react";
import image from '../../../assets/me.png'

import AboutBlurb from "../../aboutBlurb/aboutBlurb";
import Contact from "../../contact/contact";
import Headers from "../../headers/headers";
import Job from "../../job/job";

import classes from './about.module.css';

function useWindowDimensions() {
    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {height, width};
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const About = (props) => {
    let {_, width} = useWindowDimensions();

    let rightHeader = width > 576 ? "Contact" : "";

    return (
        <div className={classes.aboutSection} >
            <img src={image} alt="Me" className={classes.img} />
            <div className={classes.about} >
                <Headers leftHeader="Samantha Orizabal" rightHeader={rightHeader} />
                <div className={classes.aboutContact} >
                    <AboutBlurb />
                    <Contact />
                </div>
                <Headers leftHeader = "Experience" rightHeader="Date" />
                {props.jobs.map((job) => <Job job={job} />)}
            </div>
        </div>
    );
}

export default About;
