import {React, useEffect, useState} from "react";
import classes from './aboutBlurb.module.css';

function useWindowDimensions() {
    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {height, width};
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const AboutBlurb = () => {
    const {_, width} = useWindowDimensions();

    let blurb = "I've recently graduated from the University of Waterloo with a degree in Systems Design Engineering.";
    if (width > 760) {
        blurb += " Throughout my studies, I've interned as a Software Engineer at Faire, Ecobee, and Ontario Digital Service.";
    }
    if (width > 1100) {
        blurb += " Beyond my professional and academic pursuits, I love running, climbing, biking, reading, and painting!";
    }

    return(
        <div className={classes.aboutBlurb} >
            <p className={classes.text} >
                {blurb}
            </p>
        </div>
    );
}

export default AboutBlurb;
