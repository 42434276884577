import React from "react";
import classes from './contact.module.css';

const Contact = () => {
    return(
        <div className={classes.contact} >
            <a href="mailto:sam.orizabal@gmail.com" >sam.orizabal@gmail.com</a>
            <a href="https://github.com/orizabal" target="_blank" >github.com/orizabal</a>
            <a href="https://www.linkedin.com/in/orizabal/" target="_blank" >linkedin.com/in/orizabal</a>
        </div>
    );
}

export default Contact;
