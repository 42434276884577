import React from "react";
import classes from './job.module.css';

const Job = (props) => {
    return (
        <div>
            <div className={classes.header} >
                <p className={classes.position} >{props.job.position} @ {props.job.company}</p>
                <p className={classes.date} >{props.job.startDate} - {props.job.endDate}</p>
            </div>
            <p className={classes.description} >{props.job.description}</p>
            <hr className={classes.line} />
        </div>
    );
}

export default Job;
