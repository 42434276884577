import React from "react";
import classes from './headers.module.css';

const Headers = (props) => {
    return (
        <div className={classes.headers} >
            <p className={classes.text} >{props.leftHeader}</p>
            <p className={classes.text} >{props.rightHeader}</p>
        </div>
    );
}

export default Headers;
