import React, { Component } from 'react';

import About from './components/hoc/about/about';
import { JOBS } from './assets/jobs';

import classes from './App.module.css';

class App extends Component {
  render() {
    return (
      <div className={classes.App} >
        <section >
          <About jobs={JOBS} />
        </section>
      </div>
    );
  }
}

export default App;
